import React, { lazy } from "react";

import { ErrorRouteKeys } from "../enums";
import { ErrorRouteObject } from "../types";
import { ErrorLayout } from "../../layouts";

const NotFoundError = lazy(() => import("../../pages/error/404"));
const InternalError = lazy(() => import("../../pages/error/500"));

export const ErrorRoutesConfig: ErrorRouteObject = {
  [ErrorRouteKeys.Root]: {
    path: ErrorRouteKeys.Root,
    element: <ErrorLayout />,
  },
  [ErrorRouteKeys.NotFound]: {
    path: ErrorRouteKeys.NotFound,
    element: <NotFoundError />,
  },
  [ErrorRouteKeys.Internal]: {
    path: ErrorRouteKeys.Internal,
    element: <InternalError />,
  },
};
