import { MutationCache, QueryCache, QueryClient } from '@tanstack/react-query';
import { handleLogin } from '../utils/helper/auth';

const onError = (error: any) => {
  console.log('error', error);

  if (error.message === 'unauthorized' || error.message === 'Unauthorized' || error.status === 401) {
    handleLogin();
  }
};

export const queryCache: any = new QueryCache({
  onError,
});

export const mutationCache: any = new MutationCache({
  onError,
});

export const queryClient: any = new QueryClient({
  queryCache,
  mutationCache,
});
