import { ReactNode } from "react";

type Props = {
  children: ReactNode;
};

const Card = (props: Props) => {
  const { children } = props;

  return <div className="size-full p-4 bg-white rounded-lg">{children}</div>;
};

export default Card;
