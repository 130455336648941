import React from 'react';
import { Avatar, Button, Layout, Popover } from 'antd';
import { useGetInfoQuery } from '../../services/queries/auth/useGetInfoQuery';
import { CaretDown, SignOut } from '@phosphor-icons/react';
import { handleLogout } from '../../utils/helper';
import { NotificationBadge } from '../Notification';

const { Header } = Layout;

const MainHeader: React.FC = () => {
  const { data } = useGetInfoQuery();

  const firstChar = data?.username?.charAt(0)?.toUpperCase() || 'U';

  const menuContent = (
    <>
      <Button onClick={handleLogout} type="text" danger className="w-full text-start" icon={<SignOut />}>
        Sign out
      </Button>
    </>
  );

  return (
    <Header style={{ padding: 0 }} className="flex items-center justify-end">
      <div className="flex items-center justify-end space-x-5 px-5">
        <NotificationBadge />

        <Popover content={menuContent} title={data?.email}>
          <Button type="text" className="flex items-center space-x-1 p-0">
            <Avatar style={{ backgroundColor: '#fde3cf', color: '#f56a00', display: 'flex' }}>{firstChar}</Avatar>

            <p className="text-white">{data?.username}</p>

            <CaretDown color="#fff" />
          </Button>
        </Popover>
      </div>
    </Header>
  );
};

export default MainHeader;
