import { useQuery } from '@tanstack/react-query';

import { MarketServiceKeys } from './constants';
import { TypeGetMarketResponse } from '../../../utils/typings/market';
import { MarketServices } from '../../apis';
import { HttpError } from '../../../utils/fetcher';

export const useMarketQuery = () => {
  const queryKey = [MarketServiceKeys.GET_MARKET];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    TypeGetMarketResponse,
    HttpError
  >({
    queryKey,
    queryFn: () => MarketServices.getMarket(),
    retry: 0,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data: data,
    isError,
    queryKey,
    refetch,
  };
};
