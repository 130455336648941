import { useQuery } from '@tanstack/react-query';

import { ProductServiceKeys } from './constants';
import { ProductServices } from '../../apis';
import {
  TypeGetProductAsinSpecificationInput,
  TypeGetProductAsinSpecificationResponse,
} from '../../../utils/typings/product';
import { HttpError } from '../../../utils/fetcher';

export const useGetProductAsinsSpecificationQuery = (variables: TypeGetProductAsinSpecificationInput) => {
  const queryKey = [
    ProductServiceKeys.GET_ASINS_SPECIFICATION,
    {
      variables,
    },
  ];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    TypeGetProductAsinSpecificationResponse,
    HttpError
  >({
    queryKey,
    queryFn: () => ProductServices.getProductAsinSpecification(variables),
    retry: 0,
    refetchOnWindowFocus: false,
    enabled: !!variables?.asin,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data: data,
    isError,
    queryKey,
    refetch,
  };
};
