import {
  TypeGetProductAsinSpecificationInput,
  TypeGetProductAsinSpecificationResponse,
  TypeProductActionInput,
  TypeProductActionOffResponse,
  TypeProductActionOnResponse,
  TypeProductActionReportResponse,
  TypeProductActionResponse,
  TypeProductStatusUpdateInput,
  TypeProductStatusUpdateResponse,
  TypeUpdateProductActionInput,
  TypeUpdateProductActionResponse,
} from './../../utils/typings/product';
import { Fetcher } from '../../utils/fetcher';
import {
  TypeDeleteProductAsinInput,
  TypeGetProductAsinInput,
  TypeGetProductAsinResponse,
  TypeGetProductStatusInput,
  TypeProductActionOffInput,
  TypeProductActionOnInput,
  TypeProductActionReportInput,
  TypeProductStatusResponse,
  TypeUpdateProductAsinInput,
  TypeUpdateProductAsinResponse,
} from '../../utils/typings/product';
import { convertUrlSearchParams } from '../../utils/helper';
import { DEFAULT_PAGINATION } from '../../utils';

const getProductAsin = (payload: TypeGetProductAsinInput) => {
  const url = convertUrlSearchParams('/amazon/api/v1/products', { ...DEFAULT_PAGINATION, ...payload });

  return Fetcher.request<TypeGetProductAsinResponse>(url, {
    method: 'GET',
  });
};

const createProductAsin = (payload: TypeUpdateProductAsinInput) => {
  const url = `/amazon/api/v1/product/`;

  return Fetcher.request<TypeUpdateProductAsinResponse>(url, {
    method: 'POST',
    body: payload,
  });
};

const updateProductAsin = (payload: TypeUpdateProductAsinInput) => {
  const { id, ...product } = payload;

  const url = `/amazon/api/v1/product/${id}/`;

  return Fetcher.request<TypeUpdateProductAsinResponse>(url, {
    method: 'PATCH',
    body: product,
  });
};

const deleteProductAsin = (payload: TypeDeleteProductAsinInput) => {
  const { id } = payload;

  const url = `/amazon/api/v1/product/${id}/`;

  return Fetcher.request(url, {
    method: 'DELETE',
  });
};

const getProductStatus = (payload: TypeGetProductStatusInput) => {
  const url = convertUrlSearchParams('/amazon/api/v1/product-status/', { ...DEFAULT_PAGINATION, ...payload });

  return Fetcher.request<TypeProductStatusResponse>(url, {
    method: 'GET',
  });
};

const updateProductStatus = (payload: TypeProductStatusUpdateInput) => {
  const { pk, note } = payload;

  return Fetcher.request<TypeProductStatusUpdateResponse>(`/amazon/api/v1/product-status/${pk}/`, {
    method: 'PATCH',
    body: { note },
  });
};

const getProductActionOn = (payload: TypeProductActionOnInput) => {
  const url = convertUrlSearchParams('/amazon/api/v1/product/?active=0', { ...DEFAULT_PAGINATION, ...payload });

  return Fetcher.request<TypeProductActionOnResponse>(url, {
    method: 'GET',
  });
};

const getProductActionOff = (payload: TypeProductActionOffInput) => {
  const url = convertUrlSearchParams('/amazon/api/v1/product/?active=-1', { ...DEFAULT_PAGINATION, ...payload });

  return Fetcher.request<TypeProductActionOffResponse>(url, {
    method: 'GET',
  });
};

const getProductAction = (payload: TypeProductActionInput) => {
  const url = convertUrlSearchParams(`/amazon/api/v1/product/`, { ...payload, ...DEFAULT_PAGINATION });

  return Fetcher.request<TypeProductActionResponse>(url, {
    method: 'GET',
  });
};

const getProductActionReport = (payload: TypeProductActionReportInput) => {
  const url = convertUrlSearchParams('/amazon/api/v1/product-action/', { ...DEFAULT_PAGINATION, ...payload });

  return Fetcher.request<TypeProductActionReportResponse>(url, {
    method: 'GET',
  });
};

const updateProductAction = (payload: TypeUpdateProductActionInput) => {
  const { id, ...body } = payload;

  const url = `/amazon/api/v1/product-action/${id}/`;

  return Fetcher.request<TypeUpdateProductActionResponse>(url, {
    method: 'PATCH',
    body,
  });
};

const getProductAsinSpecification = (payload: TypeGetProductAsinSpecificationInput) => {
  const url = convertUrlSearchParams('/amazon/api/v1/product-specification/', payload);

  return Fetcher.request<TypeGetProductAsinSpecificationResponse>(url, {
    method: 'GET',
  });
};

const ProductServices = {
  getProductAsin,
  getProductStatus,
  updateProductStatus,
  createProductAsin,
  updateProductAsin,
  deleteProductAsin,
  getProductActionOn,
  getProductActionOff,
  getProductActionReport,
  getProductAction,
  updateProductAction,
  getProductAsinSpecification,
};

export default ProductServices;
