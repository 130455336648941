export const isValidUrl = (url: string) => {
  // Regular expression for validating a URL
  const urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+)(\/[\w-]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[\w-]*)?$/i;

  return urlPattern.test(url);
};

export const convertUrlSearchParams = (baseUrl: string, payload: any) => {
  const filteredParams = Object.fromEntries(Object.entries(payload).filter(([_, value]) => !!value));

  const params = new URLSearchParams(filteredParams as any);

  return params.toString() ? `${baseUrl}?${params.toString()}` : baseUrl;
};
