import './App.css';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { routesDefined } from './routes';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from './configs';
import { StrictMode } from 'react';
import { ErrorBoundary } from './components';
import { Toaster } from 'sonner';

const Routes = () => useRoutes(routesDefined);

function App() {
  const isDev = process.env.NODE_ENV === 'development';

  return (
    <QueryClientProvider client={queryClient}>
      {(isDev && (
        <StrictMode>
          <Router>
            <Routes />
          </Router>
        </StrictMode>
      )) || (
        <ErrorBoundary>
          <Router>
            <Routes />
          </Router>
        </ErrorBoundary>
      )}

      <Toaster position="bottom-right" richColors closeButton theme="light" visibleToasts={5} expand={true} />
    </QueryClientProvider>
  );
}

export default App;
