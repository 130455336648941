import { DotsThree } from '@phosphor-icons/react';
import { Dropdown, MenuProps } from 'antd';
import { tableActionMenu } from './config';
import { useLocalStorage } from 'usehooks-ts';
import { DefaultRefetchInterval, LocalStorageKeys } from '../../utils';

type Props = {
  loading: boolean;
  refetchLocalStorageKey: LocalStorageKeys;
  refetch: () => void;
  onClickCallback?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
};

const RefetchDataButton = (props: Props) => {
  const { loading, refetchLocalStorageKey, onClickCallback, refetch } = props;

  const [refetchInterval, setRefetchInterval, removeRefetchInterval] = useLocalStorage(refetchLocalStorageKey, 0);

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    onClickCallback?.(e as unknown as React.MouseEvent<HTMLElement, MouseEvent>);

    if (refetchInterval) {
      removeRefetchInterval();
    } else {
      setRefetchInterval(DefaultRefetchInterval[refetchLocalStorageKey]);
    }
  };

  const menuProps = {
    items: tableActionMenu(!!refetchInterval),
    onClick: handleMenuClick,
  };

  return (
    <div className="flex items-center">
      <Dropdown.Button
        icon={<DotsThree />}
        loading={loading}
        menu={menuProps}
        onClick={() => refetch()}
        className="flex"
      >
        Sync data
      </Dropdown.Button>
    </div>
  );
};

export default RefetchDataButton;
