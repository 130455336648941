export enum ProductServiceKeys {
  GET_ASINS = 'get-asins',
  GET_PRODUCTS_STATUS = 'get-products-status',
  EXPORT_PRODUCTS_STATUS = 'export-products-status',
  GET_PRODUCTS_ACTION_ON = 'get-products-action-on',
  GET_PRODUCTS_ACTION_OFF = 'get-products-action-off',
  GET_PRODUCTS_ACTION_REPORT = 'get-products-action-report',
  GET_PRODUCTS_ACTION_REPORT_EXPORT = 'get-products-action-report-export',
  GET_ASINS_SPECIFICATION = 'get-products-asin-specification',
}
