import { Outlet } from 'react-router-dom';
import AuthWrapper from '../Wrapper';
import { Layout } from 'antd';
import MainFooter from '../../components/Layout/Footer';
import MainHeader from '../../components/Layout/Header';
import MainSider from '../../components/Layout/Sider';
import { SocketNotification } from '../../components';

const { Content } = Layout;

const MainLayout = (): JSX.Element => {
  return (
    <AuthWrapper>
      <Layout style={{ minHeight: '100vh' }}>
        <MainSider />

        <Layout>
          <MainHeader />

          <Content className="m-5">
            {/* <Breadcrumb style={{ margin: "16px 0" }}>
              <Breadcrumb.Item>User</Breadcrumb.Item>
              <Breadcrumb.Item>Bill</Breadcrumb.Item>
            </Breadcrumb> */}

            <div
              style={{
                padding: 24,
                minHeight: 360,
              }}
            >
              <Outlet />
            </div>
          </Content>

          <MainFooter />
        </Layout>
      </Layout>
      <SocketNotification />
    </AuthWrapper>
  );
};

export default MainLayout;
