import { BellSimple } from '@phosphor-icons/react';
import { Badge, Button, Popover } from 'antd';
import NotificaitonList from './NotificaitonList';
import { useGetNotificaitonCountQuery } from '../../services';

const NotificationBadge = () => {
  const { data } = useGetNotificaitonCountQuery();

  return (
    <Popover content={<NotificaitonList />} title="Notifications">
      <Button type="text" className="flex items-center space-x-1 p-0">
        <Badge count={data?.total_count || 0} showZero size="small" color="green">
          <BellSimple color="#fff" size={20} />
        </Badge>
      </Button>
    </Popover>
  );
};

export default NotificationBadge;
