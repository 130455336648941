import { useQuery } from '@tanstack/react-query';

import { ProductServiceKeys } from './constants';
import { ProductServices } from '../../apis';
import { TypeGetProductStatusInput, TypeProductStatusResponse } from '../../../utils/typings/product';
import { HttpError } from '../../../utils/fetcher';

export const useExportProductStatusQuery = (variables: TypeGetProductStatusInput) => {
  const queryKey = [
    ProductServiceKeys.EXPORT_PRODUCTS_STATUS,
    {
      variables,
    },
  ];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    TypeProductStatusResponse,
    HttpError
  >({
    queryKey,
    queryFn: () => ProductServices.getProductStatus(variables),
    retry: 0,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data: data,
    isError,
    queryKey,
    refetch,
  };
};
