import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";

import AuthWrapper from "../Wrapper";

const AuthLayout = (): JSX.Element => {
  return (
    <AuthWrapper>
      <div className="h-screen">
        <Suspense fallback={<TopBarProgress />}>
          <Outlet />
        </Suspense>
      </div>
    </AuthWrapper>
  );
};

export default AuthLayout;
