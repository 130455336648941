import { useQuery } from '@tanstack/react-query';

import { ProductServiceKeys } from './constants';
import { ProductServices } from '../../apis';
import { TypeProductActionReportInput, TypeProductActionReportResponse } from '../../../utils/typings/product';
import { HttpError } from '../../../utils/fetcher';

export const useGetProductActionReportExportQuery = (variables: TypeProductActionReportInput) => {
  const queryKey = [
    ProductServiceKeys.GET_PRODUCTS_ACTION_REPORT_EXPORT,
    {
      variables,
    },
  ];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    TypeProductActionReportResponse,
    HttpError
  >({
    queryKey,
    queryFn: () => ProductServices.getProductActionReport(variables),
    retry: 0,
    refetchOnWindowFocus: false,
    enabled: false,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data: data,
    isError,
    queryKey,
    refetch,
  };
};
