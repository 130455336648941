import { useQuery } from '@tanstack/react-query';

import { AuthServiceKeys } from './constants';
import { AuthServices } from '../../apis';
import { TypeUserInfo } from '../../../utils/typings/auth';
import { HttpError } from '../../../utils/fetcher';
import { getAccessToken } from '../../../utils/helper';

export const useGetInfoQuery = () => {
  const queryKey = [AuthServiceKeys.GET_USER_INFO];

  const token = getAccessToken() || '';

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<TypeUserInfo, HttpError>({
    queryKey,
    queryFn: () => AuthServices.getUser(),
    retry: 0,
    refetchOnWindowFocus: true,
    staleTime: Infinity,
    enabled: !!token,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data: data,
    isError,
    queryKey,
    refetch,
  };
};
