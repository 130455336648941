import React from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CookieService } from "../../utils/helper";
import { ECookieVariable } from "../../configs";
import { getAccessToken } from "../../utils/helper/auth";
import { RouterSuspense } from "../../components";

const AuthWrapper = (props: { children: React.ReactNode }): JSX.Element => {
  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    const accessToken = getAccessToken();

    async function verifyLogin() {
      if (accessToken) {
        if (location.pathname.includes("/auth")) {
          navigate("/", {
            replace: true,
          });
        }
      } else {
        CookieService.remove(ECookieVariable.ACCESS_TOKEN_KEY);

        navigate("/auth/login", {
          replace: true,
        });
      }
    }
    verifyLogin();
  }, [location.pathname, navigate]);

  return <RouterSuspense>{props.children}</RouterSuspense>;
};

export default AuthWrapper;
