import { Fetcher } from "../../utils/fetcher";
import { TypeGetMarketResponse } from "../../utils/typings/market";

const getMarket = () => {
  const url = `/amazon/api/v1/market/`;

  return Fetcher.request<TypeGetMarketResponse>(url, {
    method: "GET",
  });
};

const MarketServices = {
  getMarket,
};

export default MarketServices;
