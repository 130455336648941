import { useQuery } from '@tanstack/react-query';

import { ProductServiceKeys } from './constants';
import { ProductServices } from '../../apis';
import { TypeProductActionReportInput, TypeProductActionReportResponse } from '../../../utils/typings/product';
import { HttpError } from '../../../utils/fetcher';

export const useGetProductActionReportQuery = (variables: TypeProductActionReportInput) => {
  const { refetchInterval, ...passthroughVariables } = variables;

  const queryKey = [
    ProductServiceKeys.GET_PRODUCTS_ACTION_REPORT,
    {
      variables,
    },
  ];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    TypeProductActionReportResponse,
    HttpError
  >({
    queryKey,
    queryFn: () => ProductServices.getProductActionReport(passthroughVariables),
    retry: 0,
    refetchOnWindowFocus: true,
    refetchInterval,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data: data,
    isError,
    queryKey,
    refetch,
  };
};
