import React from 'react';
import { Layout, Menu } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { menuItems } from './config';

const { Sider } = Layout;

const MainSider: React.FC = () => {
  const navigate = useNavigate();

  const location = useLocation();

  return (
    <Sider theme="light">
      <div className="demo-logo-vertical h-16" />
      <Menu
        defaultSelectedKeys={[location.pathname]}
        mode="inline"
        items={menuItems}
        className="text-blue-500"
        onClick={(item) => navigate(item.key)}
      />
    </Sider>
  );
};

export default MainSider;
