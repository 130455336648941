import React from "react";
import type { RouteObject } from "react-router-dom";
import { Navigate } from "react-router-dom";

import { AuthRouteKeys, ErrorRouteKeys } from "../enums";
import { AuthRoutesConfig } from "./config";

const authRouters: RouteObject = {
  path: AuthRoutesConfig[AuthRouteKeys.Root].path,
  element: AuthRoutesConfig[AuthRouteKeys.Root].element,
  children: [
    {
      path: AuthRoutesConfig[AuthRouteKeys.Root].path,
      element: <Navigate to={AuthRoutesConfig[AuthRouteKeys.Login].path} replace />,
    },
    {
      path: AuthRoutesConfig[AuthRouteKeys.Login].path,
      element: AuthRoutesConfig[AuthRouteKeys.Login].element,
    },
    {
      path: "*",
      element: <Navigate to={ErrorRouteKeys.NotFound} replace />,
    },
  ],
};

export default authRouters;
