import { TreasureChest, Archive, FileMagnifyingGlass } from '@phosphor-icons/react';
import { MenuProps } from 'antd';
import { Link } from 'react-router-dom';

type MenuItem = Required<MenuProps>['items'][number];

export const menuItems: MenuItem[] = [
  {
    label: <Link to="/dashboard">Dashboard</Link>,
    key: '/dashboard',
    icon: <TreasureChest />,
  },
  {
    label: <Link to="/asins">ASINs</Link>,
    key: '/asins',
    icon: <Archive />,
  },
  {
    label: <Link to="/report">Report</Link>,
    key: '/report',
    icon: <FileMagnifyingGlass />,
  },
];
