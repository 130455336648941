// @ts-nocheck

import { useInfiniteQuery } from '@tanstack/react-query';
import { NotificationServiceKeys } from './constants';
import { NotificationServices } from '../../apis';
import { TypeNotitycationResponse } from '../../../utils/typings/notification';

export interface NotificationListResponse {
  items: TypeNotitycationResponse[];
  next?: number;
  prevPage?: number;
}

// Define the types for your query results
type QueryResult = {
  data: NotificationListResponse;
  previous?: number;
  next?: number;
};

export const useGetNotificationListQuery = () => {
  const queryKey = [NotificationServiceKeys.GET_NOTIFICAITON_LIST];

  const {
    data,
    error,
    isLoading,
    isFetchingNextPage,
    isFetchingPreviousPage,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
  } = useInfiniteQuery({
    queryKey,
    queryFn: async ({ pageParam }: { pageParam?: number }): Promise<QueryResult> =>
      NotificationServices.getNotificationList({ page: pageParam }),
    initialPageParam: 1,
    getPreviousPageParam: (firstPage) => firstPage?.prev_page ?? undefined,
    getNextPageParam: (lastPage) => lastPage?.next_page ?? undefined,
  });

  return {
    isLoading,
    error,
    data: data?.pages?.flatMap((page) => page.results) || [],
    queryKey,
    fetchNextPage,
    fetchPreviousPage,
    hasNextPage,
    hasPreviousPage,
    isFetchingNextPage,
    isFetchingPreviousPage,
  };
};
