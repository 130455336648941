import React from "react";
import type { RouteObject } from "react-router-dom";
import { Navigate } from "react-router-dom";

import { ErrorRouteKeys } from "../enums";
import { ErrorRoutesConfig } from "./config";

const errorRoutes: RouteObject = {
  path: ErrorRoutesConfig[ErrorRouteKeys.Root].path,
  element: ErrorRoutesConfig[ErrorRouteKeys.Root].element,
  children: [
    {
      path: ErrorRoutesConfig[ErrorRouteKeys.Root].path,
      element: <Navigate to={ErrorRoutesConfig[ErrorRouteKeys.NotFound].path} replace />,
    },
    {
      path: ErrorRoutesConfig[ErrorRouteKeys.NotFound].path,
      element: ErrorRoutesConfig[ErrorRouteKeys.NotFound].element,
    },
  ],
};

export default errorRoutes;
