import { Suspense, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TopBarProgress from "react-topbar-progress-indicator";
import { MainRouteKeys } from "../../routes/enums";
import { useMatchedRoute } from "../../utils/hooks";
import { Spin } from "antd";
import { MainRoutesConfig } from "../../routes/main/config";
import { getAccessToken } from "../../utils/helper/auth";

const RouterSuspense = ({ children }: { children: React.ReactNode }) => {
  const accessToken = getAccessToken();

  const navigate = useNavigate();

  const route = useMatchedRoute(Object.values(MainRouteKeys));

  const currentRoute = MainRoutesConfig[route as MainRouteKeys];

  const redirectUrl = currentRoute?.redirectUrl;

  useEffect(() => {
    if (redirectUrl) {
      navigate(redirectUrl, { replace: true });
    }
  }, [navigate, redirectUrl]);

//   if (!accessToken) {
//     return <Spin spinning />;
//   }

  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export default RouterSuspense;
