import { parseISO, intervalToDuration } from 'date-fns';

export const convertDiffDuration = (time: string | null | undefined) => {
  if (!time) {
    // Handle null or undefined time input by returning an empty string or a default value
    return '';
  }

  const targetDate = parseISO(time);

  const now = new Date();

  const diffDuration = intervalToDuration({ start: targetDate, end: now });

  // Calculate the difference in milliseconds
  const diffInMilliseconds = now.getTime() - targetDate.getTime();

  // Check if the difference is less than one minute
  if (diffInMilliseconds < 60 * 1000) {
    // 60 seconds * 1000 milliseconds
    return 'Now';
  }

  const formattedDuration = [
    diffDuration.days ? `${diffDuration.days}d` : '',
    diffDuration.hours ? `${diffDuration.hours}h` : '',
    diffDuration.minutes ? `${diffDuration.minutes}m` : '',
  ]
    .filter(Boolean)
    .join(' ');

  return formattedDuration;
};
