import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ProductServices } from '../../apis';
import { TypeDeleteProductAsinInput } from '../../../utils/typings/product';
import { ProductServiceKeys } from '../../queries/product/constants';
import { HttpError } from '../../../utils/fetcher';

export const useDeleteProductAsinMutaion = () => {
  const queryClient = useQueryClient();

  const { isIdle, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    unknown,
    HttpError,
    TypeDeleteProductAsinInput
  >({
    mutationFn: (variables: TypeDeleteProductAsinInput) => {
      return ProductServices.deleteProductAsin(variables);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [ProductServiceKeys.GET_ASINS],
        refetchType: 'active',
      });
    },
  });

  return {
    isIdle,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};
