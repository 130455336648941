import { useMutation } from '@tanstack/react-query';
import { AuthServices } from '../../apis';
import { ECookieVariable } from '../../../configs';
import { CookieService } from '../../../utils/helper';
import { TypeGetTokenResponse, TypeGetTokenInput } from '../../../utils/typings/auth';
import { HttpError } from '../../../utils/fetcher';

export const useGetTokenMutaion = () => {
  const { isIdle, error, data, isError, isSuccess, mutate, mutateAsync } = useMutation<
    TypeGetTokenResponse,
    HttpError,
    TypeGetTokenInput
  >({
    mutationFn: (variables: TypeGetTokenInput) => {
      return AuthServices.getToken(variables);
    },
    onSuccess: (res) => {
      const { token } = res;

      CookieService.set(ECookieVariable.ACCESS_TOKEN_KEY, token);
    },
  });

  return {
    isIdle,
    isSuccess,
    error,
    data,
    isError,
    mutate,
    mutateAsync,
  };
};
