import { CookieService } from "./cookie.service";
import { ECookieVariable } from "../../configs/cookies";

export function getAccessToken() {
  const accessToken = CookieService.get(ECookieVariable.ACCESS_TOKEN_KEY);
  if (!accessToken) {
    return false;
  }

  return accessToken;
}

export const handleLogin = () => {
  CookieService.remove(ECookieVariable.ACCESS_TOKEN_KEY);

  window.location.href = "/login";
};

export const handleLogout = () => {
  CookieService.remove(ECookieVariable.ACCESS_TOKEN_KEY);

  window.location.href = "/login";
};
