export enum AuthRouteKeys {
  Root = "/auth",
  Login = "/auth/login",
}

export enum ErrorRouteKeys {
  Root = "/error",
  NotFound = "/error/404",
  Internal = "/error/500",
}

export enum MainRouteKeys {
  Root = "/",
  Dashboard = "/dashboard",
  Report = "/report",
  Asins = "/asins",
}
