import { DEFAULT_PAGINATION } from '../../utils';
import { Fetcher } from '../../utils/fetcher';
import { convertUrlSearchParams } from '../../utils/helper';
import { TypeNotitycationCountResponse, TypeNotitycationResponse } from '../../utils/typings/notification';
import { TypePagination } from '../../utils/typings/pagination';

const getNotificationCount = () => {
  return Fetcher.request<TypeNotitycationCountResponse>('/amazon/api/v1/notifications/count', {
    method: 'GET',
  });
};

const getNotificationList = (payload: TypePagination) => {
  const url = convertUrlSearchParams('/amazon/api/v1/notification/', { ...DEFAULT_PAGINATION, ...payload });

  return Fetcher.request<TypeNotitycationResponse>(url, {
    method: 'GET',
  });
};

const NotificationServices = {
  getNotificationCount,
  getNotificationList,
};

export default NotificationServices;
