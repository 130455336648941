import React from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import { ErrorRouteKeys, MainRouteKeys } from '../enums';
import { MainRoutesConfig } from './config';

const mainRoutesDefined: RouteObject = {
  path: MainRoutesConfig[MainRouteKeys.Root].path,
  element: MainRoutesConfig[MainRouteKeys.Root].element,
  children: [
    {
      path: MainRoutesConfig[MainRouteKeys.Dashboard].path,
      element: MainRoutesConfig[MainRouteKeys.Dashboard].element,
    },
    {
      path: MainRoutesConfig[MainRouteKeys.Asins].path,
      element: MainRoutesConfig[MainRouteKeys.Asins].element,
    },
    {
      path: MainRoutesConfig[MainRouteKeys.Report].path,
      element: MainRoutesConfig[MainRouteKeys.Report].element,
    },
    {
      path: '*',
      element: <Navigate to={ErrorRouteKeys.NotFound} replace />,
    },
  ],
};

export default mainRoutesDefined;
