import { MenuProps, Tooltip, Tag } from 'antd';

export const tableActionMenu = (autoSync: boolean): MenuProps['items'] => {
  return [
    {
      label: (
        <Tooltip title="Updated every 10 seconds" className="mr-2">
          <span>
            Auto sync:{' '}
            <Tag color={autoSync ? 'green' : 'default'} className="ml-2">
              {autoSync ? 'On' : 'Off'}
            </Tag>
          </span>
        </Tooltip>
      ),
      key: '1',
    },
  ];
};
