import { Divider, List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useGetNotificaitonCountQuery, useGetNotificationListQuery } from '../../services';
import clsx from 'clsx';
import { format } from 'date-fns';

type Props = {
  className?: string;
};

export enum NotificationStatus {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
}

const NotificationStatusDefined = {
  [NotificationStatus.Info]: {
    bgColor: 'bg-none',
    hoverBgColor: 'hover:bg-blue-200',
  },
  [NotificationStatus.Success]: {
    bgColor: 'bg-green-100',
    hoverBgColor: 'hover:bg-green-200',
  },
  [NotificationStatus.Warning]: {
    bgColor: 'bg-yellow-100',
    hoverBgColor: 'hover:bg-yellow-200',
  },
  [NotificationStatus.Error]: {
    bgColor: 'bg-red-100',
    hoverBgColor: 'hover:bg-red-200',
  },
};

const NotificaitonList = (props: Props) => {
  const { className } = props;

  const { data: notificationCount } = useGetNotificaitonCountQuery();

  const { data, fetchNextPage } = useGetNotificationListQuery();

  return (
    <div
      id="scrollableDiv"
      className={clsx(className)}
      style={{
        height: 400,
        overflow: 'auto',
        width: '350px',
      }}
    >
      <InfiniteScroll
        dataLength={data?.length}
        next={fetchNextPage}
        hasMore={data?.length < Number(notificationCount?.total_count || 0)}
        loader={<Skeleton paragraph={{ rows: 1 }} active />}
        endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
        scrollableTarget="scrollableDiv"
      >
        <List
          dataSource={data}
          renderItem={(item: any) => (
            <List.Item
              key={item.pk}
              className={clsx(
                '!block !px-3 rounded-lg mb-2 space-y-2',
                NotificationStatusDefined[item?.status as keyof typeof NotificationStatusDefined]?.bgColor,
                NotificationStatusDefined[item?.status as keyof typeof NotificationStatusDefined]?.hoverBgColor,
              )}
            >
              <div className="text-xs text-stone-400">{format(new Date(item?.updated_at), 'dd/MM/yyyy HH:mm:ss')}</div>

              <a href={item?.link} className="block">
                <div className="!text-blue-500">{item?.description}</div>
              </a>
            </List.Item>
          )}
        />
      </InfiniteScroll>
    </div>
  );
};

export default NotificaitonList;
