import React from 'react';
import { Component } from 'react';

type Props = {
  children: JSX.Element | JSX.Element[];
};

type State = {
  hasError?: boolean;
};

class ErrorBoundary extends Component<Props, State> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="h-screen w-full flex-col items-center justify-center flex">
          <p className="text-4xl font-bold">Sorry, something went wrong</p>
          <p className="text-xl">Please again try laterPlease again try later!</p>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
