import React, { lazy } from 'react';

import { MainRouteKeys } from '../enums';
import { MainRouteObject } from '../types';
import { Archive, FileMagnifyingGlass, TreasureChest } from '@phosphor-icons/react';
import MainLayout from '../../layouts/Main';

const AsinsPage = lazy(() => import('../../pages/asins/list'));
const DashboardPage = lazy(() => import('../../pages/dashboard'));
const ReportPage = lazy(() => import('../../pages/reports'));

export const MainRoutesConfig: MainRouteObject = {
  [MainRouteKeys.Root]: {
    path: MainRouteKeys.Root,
    name: 'Home',
    title: 'Home',
    element: <MainLayout />,
    redirectUrl: MainRouteKeys.Dashboard,
  },
  [MainRouteKeys.Dashboard]: {
    path: MainRouteKeys.Dashboard,
    name: 'Dashboard',
    title: 'Dashboard ',
    element: <DashboardPage />,
    icon: <TreasureChest />,
  },
  [MainRouteKeys.Asins]: {
    path: MainRouteKeys.Asins,
    name: 'ASINs',
    title: 'ASINs ',
    element: <AsinsPage />,
    icon: <Archive />,
  },
  [MainRouteKeys.Report]: {
    path: MainRouteKeys.Report,
    name: 'Report',
    title: 'Report ',
    element: <ReportPage />,
    icon: <FileMagnifyingGlass />,
  },
};
