import { useQuery } from '@tanstack/react-query';

import { ProductServiceKeys } from './constants';
import { ProductServices } from '../../apis';
import { TypeGetProductAsinInput, TypeGetProductAsinResponse } from '../../../utils/typings/product';
import { HttpError } from '../../../utils/fetcher';

export const useGetProductAsinsQuery = (variables: TypeGetProductAsinInput) => {
  const queryKey = [
    ProductServiceKeys.GET_ASINS,
    {
      variables,
    },
  ];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    TypeGetProductAsinResponse,
    HttpError
  >({
    queryKey,
    queryFn: () => ProductServices.getProductAsin(variables),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data: data,
    isError,
    queryKey,
    refetch,
  };
};
