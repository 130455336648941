import { Spin } from "antd";
import { Suspense } from "react";
import { Outlet } from "react-router-dom";

const Error = (): JSX.Element => {
  return (
    <Suspense fallback={<Spin spinning />}>
      <Outlet />
    </Suspense>
  );
};

export default Error;
