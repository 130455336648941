import React, { lazy } from "react";

import { AuthRouteKeys } from "../enums";
import { AuthRouteObject } from "../types";
import { AuthLayout } from "../../layouts";

const LoginPage = lazy(() => import("../../pages/auth/login"));

export const AuthRoutesConfig: AuthRouteObject = {
  [AuthRouteKeys.Root]: {
    path: AuthRouteKeys.Root,
    name: "route:auth.name",
    title: "route:auth.title",
    element: <AuthLayout />,
  },
  [AuthRouteKeys.Login]: {
    path: AuthRouteKeys.Login,
    name: "Đăng nhập",
    title: "Đăng nhập",
    element: <LoginPage />,
  },
};
