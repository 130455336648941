import { Fetcher } from '../../utils/fetcher';
import { TypeGetTokenInput, TypeGetTokenResponse, TypeUserInfo } from '../../utils/typings/auth';

const getToken = (payload: TypeGetTokenInput) => {
  return Fetcher.request<TypeGetTokenResponse>('/amazon/api/api-token-auth/', {
    method: 'POST',
    body: payload,
  });
};

const getUser = () => {
  return Fetcher.request<TypeUserInfo>('/user/', {
    method: 'GET',
  });
};

const AuthServices = {
  getToken,
  getUser,
};

export default AuthServices;
