import { toast } from 'sonner';
import { getAccessToken } from './helper';

export class HttpError extends Error {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    this.name = 'HttpError';
    this.status = status;
  }
}

type FetcherOptions = {
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  headers?: { [key: string]: string };
  body?: Record<string, any>; // Cải thiện type cho body
};

export class Fetcher {
  static async request<T>(path: string, options: FetcherOptions = {}): Promise<T> {
    const domain = process.env.REACT_APP_URL_API_MAIN_CLIENT_SIDE;
    if (!domain) {
      throw new Error('API base URL is not defined');
    }

    const url = `${domain}${path}`;

    try {
      const token = getAccessToken() || '';

      const headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...options.headers,
      });

      if (token) {
        headers.append('Authorization', `Token ${token}`);
      }

      const response = await fetch(url, {
        method: options.method || 'GET',
        headers,
        body: options.body ? JSON.stringify(options.body) : undefined,
      });

      if (!response.ok) {
        const errorText = await response.json();

        if (typeof errorText === 'object') {
          Object.values(errorText)?.forEach((item) => {
            if (Array.isArray(item)) {
              item.forEach((ele) => {
                ele && toast.error(JSON.stringify(ele));
              });
            } else {
              toast.error(JSON.stringify(item));
            }
          });
        }

        throw new HttpError(
          `HTTP error! Status: ${response.status}, Details: ${JSON.stringify(errorText)}`,
          response.status,
        );
      }

      const data: T = await response.json();
      return data;
    } catch (error) {
      if (error instanceof HttpError) {
        console.error('Fetch error:', error.message, 'Status:', error.status);
      } else if (error instanceof Error) {
        console.error('Fetch error:', error.message);
      } else {
        console.error('Unknown error occurred');
      }
      throw error;
    }
  }
}
