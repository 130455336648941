import { useQuery } from '@tanstack/react-query';

import { NotificationServiceKeys } from './constants';
import { NotificationServices } from '../../apis';
import { HttpError } from '../../../utils/fetcher';
import { TypeNotitycationCountResponse } from '../../../utils/typings/notification';

export const useGetNotificaitonCountQuery = () => {
  const queryKey = [NotificationServiceKeys.GET_NOTIFICATION_COUNT];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    TypeNotitycationCountResponse,
    HttpError
  >({
    queryKey,
    queryFn: () => NotificationServices.getNotificationCount(),
    retry: 0,
    refetchOnWindowFocus: true,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data: data,
    isError,
    queryKey,
    refetch,
  };
};
