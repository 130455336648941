import { useQuery } from '@tanstack/react-query';

import { ProductServiceKeys } from './constants';
import { ProductServices } from '../../apis';
import { TypeProductActionInput, TypeProductActionResponse } from '../../../utils/typings/product';
import { HttpError } from '../../../utils/fetcher';

export const useGetProductActionQuery = (variables: TypeProductActionInput) => {
  const { refetchInterval, ...passthroughVariables } = variables;

  const queryKey = [
    ProductServiceKeys.GET_ASINS,
    {
      variables,
    },
  ];

  const { isLoading, error, data, isError, isFetching, isLoadingError, refetch } = useQuery<
    TypeProductActionResponse,
    HttpError
  >({
    queryKey,
    queryFn: () => ProductServices.getProductAction(passthroughVariables),
    retry: 0,
    refetchOnWindowFocus: false,
  });

  return {
    isLoading,
    isLoadingError,
    isFetching,
    error,
    data: data,
    isError,
    queryKey,
    refetch,
  };
};
