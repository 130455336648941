import { useQueryClient } from '@tanstack/react-query';
import { useEffect } from 'react';
import { toast } from 'sonner';
import { NotificationServiceKeys } from '../../services/queries/notification/constants';

const SocketNotification = () => {
  const queryClient = useQueryClient();

  useEffect(() => {
    const socket = new WebSocket(process.env.REACT_APP_WEB_SOCKET_URL || '');

    socket.onopen = () => {
      console.log('WebSocket connection opened');
    };

    socket.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);

        if (data.type === 'notification') {
          queryClient.invalidateQueries({
            queryKey: [NotificationServiceKeys.GET_NOTIFICATION_COUNT],
            refetchType: 'active',
          });
          toast(
            <a href={data?.link} target="_blank" className="block !text-blue-500" rel="noreferrer">
              {data?.description}
            </a>,
            { duration: 10000 },
          );
        }
      } catch (error) {
        console.error('Error parsing message data:', error);
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    socket.onclose = (event) => {
      console.log('WebSocket connection closed:', event.reason);
    };

    return () => {
      socket.close();
    };
  }, []);

  return null;
};

export default SocketNotification;
